.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.c-app {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  min-height: 100vh;
}


.c-app-login {
  background-color: #EA4726;
}
.c-app-default {
  min-height: 100vh;
  background-color: #F2F2F2;
}

.btn-login {
  background-color: #EA4726 !important;
  border-color: transparent !important;
}

.custom-shadow-lg {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.card {
  /* -webkit-box-shadow: 0 1px 2.94px 0.06px rgb(4 26 55 / 16%); */
  /* box-shadow: 0 1px 2.94px 0.06px rgb(4 26 55 / 16%); */
  border: none;
  margin-bottom: 10px;
  overflow: hidden;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  /* border: 1px solid rgba(0,0,0,.125); */
}

.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 0.7rem 0.7rem 0.7rem 0.7rem;
}

.sidebar-brand, .sidebar-toggler, .sidebar-nav {
  /* background: -webkit-gradient(linear, left top, right top, from(#f36533), to(#f36533)); */
  background: #EA4726;
}



.header{
  background: #F8F9FA;
  border-bottom: none;
  border-top-left-radius: 20px;
}

.MuiTableCell-head {
  font-weight: bold !important;
}

.block-ui-container{
  z-index: 1500 !important;
}
.sidebar-nav-group.active {     background: #ECECEC;color:rgb(255, 255, 255) !important}

.sidebar-nav .nav-link.active {
  background: #ECECEC;
  color:black !important
}

.nav-link.active > .nav-icon {
  background: #ECECEC;
  color:black !important
}

.nav-icon, .nav-link {
  color: white !important;
}

.base-circle {
  width: 50px;
  height: 48px;
  shape-outside: circle();
  background: #EA4726;
  position: absolute;
  padding-left: var(--cui-sidebar-occupy-start, 0);  
}

.nav-circle-bottom {
  width: 30px;
  height: 5px;
  shape-outside: circle();
  background: #BB391E;
  position: absolute;
  right: 0;
  bottom: 0;
  /* padding-left: var(--cui-sidebar-occupy-start, 0);   */
}

.nav-circle-top {
  width: 30px;
  height: 10px;
  shape-outside: circle();
  background: #BB391E;
  position: absolute;
  right: 0;
  top: 0;
  margin-top: -10px;
}

.footer {
  position: relative;
  border-bottom-left-radius: 20px;
}

.hide-item {
  display: none;
}

.nav-item {
  position: relative;
}

/* .nav-item, .nav-group {
  padding: 8px;
} */

.nav-link {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

/* F6F7F8 */
.custom-nav-item {
  position: relative;
}

.custom-nav-item b:nth-child(1) {
  position: absolute;
  top: -20px;
  height: 20px;
  width: 100%;
  background: #fff;
  display: none;
}

.custom-nav-item b:nth-child(1)::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #EA4726;
  border-bottom-right-radius: 20px;
}

.custom-nav-item b:nth-child(2) {
  position: absolute;
  bottom: -20px;
  height: 20px;
  width: 100%;
  background: #fff;
  display: none;
}

.custom-nav-item b:nth-child(2)::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #EA4726;
  border-top-right-radius: 20px;
}


.nav-link.active {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}


.nav-link.active > b:nth-child(1) {
  display: block;
}

.nav-link.active > b:nth-child(2) {
  display: block;
}

.simplebar-content-wrapper {
  height: 100% !important;
}

.header {
  /* background: url("./assets/logo.png") right no-repeat; */
  background-color: #ECECEC;
  background-size: 50% 150%;
}

.custom-btn {
  color: white !important;
  border-color: #EA4726 !important;
  background-color: #EA4726 !important;
}

.pointer {
  cursor: pointer;
}

.offcanvas.show {
  transform: none !important;
}
.offcanvas-end {
  width: 550px !important;
} 

.card.bg-c-green {
  border-radius: 15px;
  font-size: 1rem;
}

.custom-text {
  font-weight: 500;
  font-size: 1rem;
  margin: 0; 
}

.custom-card {
  border-radius: 15px;
}

.custom-card .card-header {
  background-color: unset;
  border-bottom: none;
}

#langDrp {
  padding-top: 0;
}

.nav-link.active:before {
  content: '';
  position: absolute;
  top:-20px;
  right: 0px;
  width: 20px;
  height:20px;
  background: transparent;
  border-radius: 50%;
  box-shadow: 10px 10px 0 #EBEDEF;
}

.nav-link.active:after {
  content: '';
  position: absolute;
  bottom:-20px;
  right: 0px;
  width: 20px;
  height:20px;
  background: transparent;
  border-radius: 50%;
  box-shadow: 10px -10px 0 #EBEDEF;
}

.MuiPaper-root {
  box-shadow: unset !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.sidebar-nav::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.sidebar-nav {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.alice-carousel div .alice-carousel__wrapper ul li div .active__carousel {
  box-shadow: 0 0 10px 0px #EA4726;
  transform: scale(1.0);
}

.imu_gif {
  opacity: 0.2;
  text-align: center;
}

.imu_gif.active {
  opacity: 1;
}

.img-thumbnail {
    margin-bottom: 5px;
}

.img__selected__thumbnail {
  border: 3px solid #52fa63;
}

.img__labeled__thumbnail__div {
  position: relative;
  display: inline-block;
}

.img__labeled__thumbnail__icon {
  position: absolute;
  left: 20%;
  top: 5%;
  transform: translate(-50%, -50%);
  color: green;
}


